import LinearProgress from '@mui/material/LinearProgress';
import { Navigate, Outlet, Route } from 'react-router';
import React, { memo, Suspense } from 'react';
import { Routes } from 'react-router-dom';

import { AppWrapper } from 'containers/AppComponents';
import { AuthWrapper } from 'components/AuthWrapper';
import { useRole } from 'context/PermissionsContext';
import { UserRole } from 'clients/CoreService';

interface RoutesConfig {
	path: string;
	element: React.ReactElement;
	children?: RoutesConfig[];
	allowedRoles?: string[];
}

const LazyOrganizers = React.lazy(() => import('pages/OrganizersPage'));
const LazyMeetings = React.lazy(() => import('pages/MeetingsPage'));
const LazyMeetingsDetails = React.lazy(() => import('pages/MeetingDetailsPage'));
const LazyReports = React.lazy(() => import('pages/ReportsPage'));
const LazyReportsMeeting = React.lazy(() => import('pages/ReportsMeeting'));
const LazyOrganizations = React.lazy(() => import('pages/OrganizationsPage'));
const LazyMeetingVerification = React.lazy(
	() => import('pages/MeetingVerificationPage/MeetingVerificationPage'),
);
const LazyAdminsChat = React.lazy(() => import('pages/AdminsChatPage'));
const LazyInstantMeeting = React.lazy(() => import('pages/InstantMeetingPage'));

const LazyLogin = React.lazy(() => import('containers/Login'));
const LazyForgotPasswordReset = React.lazy(() => import('containers/ForgotPasswordReset'));
const LazyForgotPasswordEmail = React.lazy(() => import('containers/ForgotPasswordEmail'));

const LazyJoinMeetingMagic = React.lazy(() => import('pages/MeetingPage/MeetingPage.join.magic'));
const LazyJoinMeetingToken = React.lazy(() => import('pages/MeetingPage/MeetingPage.join.token'));
const LazyJoinMeetingCode = React.lazy(() => import('pages/MeetingPage/code'));

export const appPaths = {
	meetings: '/admin/meetings/',
	joinMeetingMagic: '/join-meeting/magic/:magicLink',
	joinMeetingMagicAuth: '/join-meeting/magic/:magicLink/authenticate',
	joinMeetingToken: '/join-meeting/token/:meetingId',
	joinMeetingTokenChat: '/join-meeting/token/:meetingId/chat/session/:sessionId',
	joinMeetingCode: '/join',
	reports: '/admin/reports/',
	reportsMeeting: '/admin/reports/:meetingId',
	auth: {
		url: 'auth',
		children: {
			login: 'login',
			forgotPasswordEmail: 'forgotPasswordEmail',
			forgotPasswordReset: 'forgotPasswordReset',
		},
	},
	instantMeeting: '/instant/:jitsiApiKey/:meetingId',
};

const NO_ROLE = 'NO_ROLE';
const allRoutes: RoutesConfig[] = [
	{
		path: 'admin',
		allowedRoles: [UserRole.PLATFORM_ADMIN, UserRole.ORGANIZATION_ADMIN],
		element: <AppWrapper />,
		children: [
			{ path: 'organizers', element: <LazyOrganizers /> },
			{ path: 'meetings', element: <LazyMeetings /> },
			{ path: 'meetings/*', element: <LazyMeetingsDetails /> },
			{ path: 'reports', element: <LazyReports /> },
			{ path: 'reports/:meetingId/*', element: <LazyReportsMeeting /> },
			{
				path: 'organizations',
				allowedRoles: [UserRole.PLATFORM_ADMIN],
				element: <LazyOrganizations />,
			},
		],
	},
	{
		path: appPaths.instantMeeting,
		element: <LazyInstantMeeting />,
	},
	{
		path: appPaths.auth.url,
		allowedRoles: [
			NO_ROLE,
			UserRole.ATTENDEE,
			UserRole.SCREENER,
			UserRole.IN_ROOM_ATTENDEE,
			UserRole.SPEAKER,
			UserRole.OBSERVER,
		],
		element: <AuthWrapper />,
		children: [
			{ path: appPaths.auth.children.login, element: <LazyLogin /> },
			{ path: appPaths.auth.children.forgotPasswordEmail, element: <LazyForgotPasswordEmail /> },
			{ path: appPaths.auth.children.forgotPasswordReset, element: <LazyForgotPasswordReset /> },
		],
	},
	{
		path: appPaths.joinMeetingMagic,
		element: <Outlet />,
		children: [
			{ path: '', element: <LazyJoinMeetingMagic /> },
			{ path: 'authenticate', element: <LazyMeetingVerification /> },
		],
	},
	{
		path: appPaths.joinMeetingToken,
		element: <Outlet />,
		children: [
			{ path: '', element: <LazyJoinMeetingToken /> },
			{ path: 'chat/session/:sessionId', element: <LazyAdminsChat /> },
		],
	},
		{
		path: appPaths.joinMeetingCode,
		element: <Outlet />,
		children: [{ path: '', element: <LazyJoinMeetingCode /> }],
	},
];

export const homePaths: Record<UserRole | typeof NO_ROLE, string> = {
	OrganizationAdmin: '/admin/meetings',
	PlatformAdmin: '/admin/meetings',
	[UserRole.ATTENDEE]: 'auth/login',
	[UserRole.SCREENER]: 'auth/login',
	[UserRole.OBSERVER]: 'auth/login',
	[UserRole.IN_ROOM_ATTENDEE]: 'auth/login',
	[UserRole.SPEAKER]: 'auth/login',
	[UserRole.MAIN_ROOM_ATTENDEE]: 'auth/login',
	[NO_ROLE]: 'auth/login',
};

function genRoutes(routes: RoutesConfig[], role?: string) {
	return routes
		.filter(({ allowedRoles }) => {
			if (!allowedRoles) return true;
			if (!role) return false;
			return allowedRoles.includes(role);
		})
		.map(({ path, element, children }) => {
			const suspendedElement = <Suspense fallback={<LinearProgress />}>{element}</Suspense>;

			return children ? (
				<Route key={path} path={path} element={suspendedElement}>
					{genRoutes(children, role)}
				</Route>
			) : (
				<Route key={path} path={path} element={suspendedElement} />
			);
		});
}

const AuthRoutes = memo(({ role = NO_ROLE }: { role?: UserRole | typeof NO_ROLE }) => {
	return (
		<Routes>
			{genRoutes(allRoutes, role)}
			<Route path={'*'} element={<Navigate to={homePaths[role]} replace />} />
		</Routes>
	);
});

export function AppRoutes() {
	return <AuthRoutes role={useRole()} />;
}
