/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddParticipantRequest } from '../models/AddParticipantRequest';
import type { CanAcceptParticipantResponse } from '../models/CanAcceptParticipantResponse';
import type { ChangeParticipantPermissionsRequest } from '../models/ChangeParticipantPermissionsRequest';
import type { ChangeParticipantSettingsRequest } from '../models/ChangeParticipantSettingsRequest';
import type { DataQueryOrder } from '../models/DataQueryOrder';
import type { DataQueryOrderBy } from '../models/DataQueryOrderBy';
import type { DataQueryPage } from '../models/DataQueryPage';
import type { DataQueryRowsPerPage } from '../models/DataQueryRowsPerPage';
import type { DataQuerySearchText } from '../models/DataQuerySearchText';
import type { LiveParticipantResponse } from '../models/LiveParticipantResponse';
import type { LiveParticipantsListResponse } from '../models/LiveParticipantsListResponse';
import type { Pagination_any_ } from '../models/Pagination_any_';
import type { ParticipantResponse } from '../models/ParticipantResponse';
import type { ResultQuestionResponse } from '../models/ResultQuestionResponse';
import type { ScreenNextParticipantRequest } from '../models/ScreenNextParticipantRequest';
import type { ScreenParticipantRequest } from '../models/ScreenParticipantRequest';
import type { SetParticipantLiveRequest } from '../models/SetParticipantLiveRequest';
import type { SpeakersResponse } from '../models/SpeakersResponse';
import type { UpdateParticipantRequest } from '../models/UpdateParticipantRequest';
import type { VerificationParticipantResponse } from '../models/VerificationParticipantResponse';
import type { VerifyAnonymousParticipantRequest } from '../models/VerifyAnonymousParticipantRequest';
import { request as __request } from '../core/request';

export class ParticipantService {

    /**
     * @param participantId
     * @param requestBody
     * @returns ParticipantResponse
     * @throws ApiError
     */
    public static async endScreening(
        participantId: string,
        requestBody: ScreenParticipantRequest,
    ): Promise<ParticipantResponse> {
        const result = await __request({
            method: 'POST',
            path: `/participants/${participantId}/screen`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param meetingId
     * @param requestBody
     * @returns ParticipantResponse
     * @throws ApiError
     */
    public static async addParticipant(
        meetingId: number,
        requestBody: AddParticipantRequest,
    ): Promise<ParticipantResponse> {
        const result = await __request({
            method: 'POST',
            path: `/participants/meetings/${meetingId}`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @returns ResultQuestionResponse
     * @throws ApiError
     */
    public static async screenNextParticipant(
        requestBody: ScreenNextParticipantRequest,
    ): Promise<ResultQuestionResponse> {
        const result = await __request({
            method: 'POST',
            path: `/participants/questions/screen/next`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param participantId
     * @returns ParticipantResponse
     * @throws ApiError
     */
    public static async disconnectParticipantFromBreakoutRoom(
        participantId: string,
    ): Promise<ParticipantResponse> {
        const result = await __request({
            method: 'POST',
            path: `/participants/${participantId}/disconnect`,
        });
        return result.body;
    }

    /**
     * @param meetingId
     * @param participantId
     * @param requestBody
     * @returns ParticipantResponse
     * @throws ApiError
     */
    public static async updateParticipant(
        meetingId: number,
        participantId: string,
        requestBody: UpdateParticipantRequest,
    ): Promise<ParticipantResponse> {
        const result = await __request({
            method: 'PUT',
            path: `/participants/${participantId}/meetings/${meetingId}`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param meetingId
     * @param participantId
     * @returns ParticipantResponse
     * @throws ApiError
     */
    public static async deleteParticipant(
        meetingId: number,
        participantId: string,
    ): Promise<ParticipantResponse> {
        const result = await __request({
            method: 'DELETE',
            path: `/participants/${participantId}/meetings/${meetingId}`,
        });
        return result.body;
    }

    /**
     * @param participantId
     * @param requestBody
     * @returns ParticipantResponse
     * @throws ApiError
     */
    public static async setParticipantLive(
        participantId: string,
        requestBody: SetParticipantLiveRequest,
    ): Promise<ParticipantResponse> {
        const result = await __request({
            method: 'POST',
            path: `/participants/${participantId}/live`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param meetingId
     * @param rowsPerPage
     * @param page
     * @param orderBy
     * @param order
     * @param filters
     * @param searchText
     * @returns Pagination_any_
     * @throws ApiError
     */
    public static async getAllParticipants(
        meetingId: number,
        rowsPerPage: DataQueryRowsPerPage,
        page: DataQueryPage,
        orderBy?: DataQueryOrderBy,
        order?: DataQueryOrder,
        filters?: Array<string>,
        searchText?: DataQuerySearchText,
    ): Promise<Pagination_any_> {
        const result = await __request({
            method: 'POST',
            path: `/participants/meetings/${meetingId}/all`,
            query: {
                'rowsPerPage': rowsPerPage,
                'page': page,
                'orderBy': orderBy,
                'order': order,
                'filters': filters,
                'searchText': searchText,
            },
        });
        return result.body;
    }

    /**
     * @param meetingId
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static async importParticipants(
        meetingId: number,
        requestBody: any,
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/participants/meetings/${meetingId}/import`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param rowsPerPage
     * @param page
     * @returns LiveParticipantsListResponse
     * @throws ApiError
     */
    public static async getLiveParticipantsList(
        rowsPerPage: number,
        page: number,
    ): Promise<LiveParticipantsListResponse> {
        const result = await __request({
            method: 'GET',
            path: `/participants/live`,
            query: {
                'rowsPerPage': rowsPerPage,
                'page': page,
            },
        });
        return result.body;
    }

    /**
     * Mute/unmute user video or audio
     * @param participantId
     * @param requestBody
     * @returns LiveParticipantResponse
     * @throws ApiError
     */
    public static async changeParticipantSettings(
        participantId: string,
        requestBody: ChangeParticipantSettingsRequest,
    ): Promise<LiveParticipantResponse> {
        const result = await __request({
            method: 'POST',
            path: `/participants/${participantId}/settings`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * Set browser permissions for video or audio
     * @param requestBody
     * @returns LiveParticipantResponse
     * @throws ApiError
     */
    public static async changeParticipantPermissions(
        requestBody: ChangeParticipantPermissionsRequest,
    ): Promise<LiveParticipantResponse> {
        const result = await __request({
            method: 'POST',
            path: `/participants/permissions`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @returns LiveParticipantResponse
     * @throws ApiError
     */
    public static async getLiveParticipantSettings(): Promise<LiveParticipantResponse> {
        const result = await __request({
            method: 'GET',
            path: `/participants/settings/states/live`,
        });
        return result.body;
    }

    /**
     * @param code
     * @returns VerificationParticipantResponse
     * @throws ApiError
     */
    public static async verifyParticipant(
        code: string,
    ): Promise<VerificationParticipantResponse> {
        const result = await __request({
            method: 'POST',
            path: `/participants/verifications/codes/${code}/check`,
        });
        return result.body;
    }

    /**
     * @param isSessionVerification
     * @returns VerificationParticipantResponse
     * @throws ApiError
     */
    public static async sendVerificationCode(
        isSessionVerification?: boolean,
    ): Promise<VerificationParticipantResponse> {
        const result = await __request({
            method: 'POST',
            path: `/participants/verifications/codes/send`,
            query: {
                'isSessionVerification': isSessionVerification,
            },
        });
        return result.body;
    }

    /**
     * @param participantId
     * @param requestBody
     * @returns ParticipantResponse
     * @throws ApiError
     */
    public static async verifyAnonymousParticipant(
        participantId: string,
        requestBody: VerifyAnonymousParticipantRequest,
    ): Promise<ParticipantResponse> {
        const result = await __request({
            method: 'POST',
            path: `/participants/anonymous/${participantId}/verifications`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @returns CanAcceptParticipantResponse
     * @throws ApiError
     */
    public static async canAcceptParticipant(): Promise<CanAcceptParticipantResponse> {
        const result = await __request({
            method: 'GET',
            path: `/participants/rooms/free`,
        });
        return result.body;
    }

    /**
     * @param meetingId
     * @returns SpeakersResponse
     * @throws ApiError
     */
    public static async getSpeakers(
        meetingId: number,
    ): Promise<SpeakersResponse> {
        const result = await __request({
            method: 'GET',
            path: `/participants/speakers`,
            query: {
                'meetingId': meetingId,
            },
        });
        return result.body;
    }

    /**
     * @param activeSessionId
     * @returns void
     * @throws ApiError
     */
    public static async setActiveSession(
        activeSessionId: string,
    ): Promise<void> {
        const result = await __request({
            method: 'POST',
            path: `/participants/sessions/active`,
            query: {
                'activeSessionId': activeSessionId,
            },
        });
        return result.body;
    }

    /**
     * @returns ParticipantResponse Ok
     * @throws ApiError
     */
    public static async switchAttendeeRole(): Promise<ParticipantResponse> {
        const result = await __request({
            method: 'PUT',
            path: `/participants/roles/switch`,
        });
        return result.body;
    }

      /**
     * Download participants CSV
     * @param meetingId
     * @returns string (CSV content)
     * @throws ApiError
     */
      public static async downloadParticipantsCsv(
        meetingId: number,
    ): Promise<string> {
        const result = await __request({
            method: 'GET',
            path: `/participants/meetings/${meetingId}/participants/csv`,
            headers: {
                'Accept': 'application/csv',
            },
        });
        return result.body;
    }

       /**
     * Get participant by magic link token and meeting ID
     * @param magicLinkToken
     * @param meetingId
     * @returns ParticipantResponse
     * @throws ApiError
     */
       public static async getParticipantByMagicLinkToken(
        magicLinkToken: string,
        meetingId: number,
    ): Promise<ParticipantResponse> {
        const result = await __request({
            method: 'GET',
            path: `/participants/magic-link`,
            query: {
                'magicLinkToken': magicLinkToken,
                'meetingId': meetingId,
            },
        });
        return result.body;
    }

    /**
     * Get participant by ID
     * @param participantId
     * @returns ParticipantResponse
     * @throws ApiError
     */
    public static async getParticipantById(
        participantId: string,
    ): Promise<ParticipantResponse> {
        const result = await __request({
            method: 'GET',
            path: `/participants/${participantId}`,
        });
        return result.body;
    }
}