import { request as __request } from '../core/request';

export interface SendSMSRequest {
  subject: string;
  text: string;
}

export class MessageService {
  /**
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public static async sendSMS(
    meetingId: number,
    requestBody: SendSMSRequest,
  ): Promise<void> {
    // eslint-disable-next-line no-debugger
    debugger;
    const result = await __request({
      method: 'POST',
      path: `/message/${meetingId}/sms/send`,
      body: requestBody,
    });
    return result.body;
  }
}