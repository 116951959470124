/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MeetingVoiceBroadcastingProgressResponse } from '../models/MeetingVoiceBroadcastingProgressResponse';
import type { MeetingVoiceBroadcastingResponse } from '../models/MeetingVoiceBroadcastingResponse';
import type { SetVoiceBroadcastingRequest } from '../models/SetVoiceBroadcastingRequest';
import { request as __request } from '../core/request';

export class VoiceBroadcastingService {

    /**
     * @param meetingId
     * @returns MeetingVoiceBroadcastingResponse
     * @throws ApiError
     */
    public static async getVoiceBroadcasting(
        meetingId: number,
    ): Promise<MeetingVoiceBroadcastingResponse> {
        const result = await __request({
            method: 'GET',
            path: `/voice_broadcasting/${meetingId}/broadcasting/settings`,
        });
        return result.body;
    }

    /**
     * @param meetingId
     * @param requestBody
     * @returns MeetingVoiceBroadcastingResponse
     * @throws ApiError
     */
    public static async setVoiceBroadcasting(
        meetingId: number,
        requestBody: SetVoiceBroadcastingRequest,
    ): Promise<MeetingVoiceBroadcastingResponse> {
        const result = await __request({
            method: 'POST',
            path: `/voice_broadcasting/${meetingId}/broadcasting/settings`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param meetingId
     * @returns any
     * @throws ApiError
     */
    public static async cancelVoiceBroadcasting(
        meetingId: number,
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/voice_broadcasting/${meetingId}/broadcasting/cancel`,
        });
        return result.body;
    }

    /**
     * @param meetingId
     * @returns MeetingVoiceBroadcastingProgressResponse
     * @throws ApiError
     */
    public static async getVoiceBroadcastingProgress(
        meetingId: number,
    ): Promise<MeetingVoiceBroadcastingProgressResponse> {
        const result = await __request({
            method: 'GET',
            path: `/voice_broadcasting/${meetingId}/broadcasting/progress`,
        });
        return result.body;
    }

    /**
     * Retrieves the download URL for the broadcast audio file for a specific meeting.
     * 
     * @param meetingId The ID of the meeting.
     * @returns The download URL and filename.
     * @throws ApiError if the request fails.
    */
    public static async getBroadcastAudioDownloadUrl(meetingId: number): Promise<{ url: string; filename: string; fileSize: number }> {
        const result = await __request({
            method: 'GET',
            path: `/voice_broadcasting/${meetingId}/download-broadcasting-audio`,
        });
        return result.body;
    }

    /**
     * Retrieves the download URL for the voicemail audio file for a specific meeting.
     * 
     * @param meetingId The ID of the meeting.
     * @returns The download URL and filename.
     * @throws ApiError if the request fails.
    */
    public static async getVoicemailAudioDownloadUrl(meetingId: number): Promise<{ url: string; filename: string; fileSize: number }> {
        const result = await __request({
            method: 'GET',
            path: `/voice_broadcasting/${meetingId}/download-voicemail-audio`,
        });
        return result.body;
    }

    /**
     * Uploads a broadcast audio file for a specific meeting.
     * 
     * @param meetingId The ID of the meeting.
     * @param file The audio file to upload.
     * @throws ApiError if the file upload fails.
    */
    public static async uploadBroadcastAudio(meetingId: number, file: File): Promise<void> {
        await __request({
            method: 'POST',
            path: `/voice_broadcasting/${meetingId}/upload-broadcasting-audio`,
            formData: { file },
        });
    }

    /**
     * Uploads a voicemail audio file for a specific meeting.
     * 
     * @param meetingId The ID of the meeting.
     * @param file The audio file to upload.
     * @throws ApiError if the file upload fails.
    */
    public static async uploadVoicemailAudio(meetingId: number, file: File): Promise<void> {
        await __request({
            method: 'POST',
            path: `/voice_broadcasting/${meetingId}/upload-voicemail-audio`,
            formData: { file },
        });
    }

    /**
     * Deletes the broadcast audio file for a specific meeting.
     * 
     * @param meetingId The ID of the meeting.
     * @throws ApiError if the file deletion fails.
    */
    public static async deleteBroadcastAudio(meetingId: number): Promise<void> {
        await __request({
            method: 'DELETE',
            path: `/voice_broadcasting/${meetingId}/delete-voice-broadcasting-audio`,
        });
    }

    /**
     * Deletes the voicemail audio file for a specific meeting.
     * 
     * @param meetingId The ID of the meeting.
     * @throws ApiError if the file deletion fails.
    */
    public static async deleteVoicemailAudio(meetingId: number): Promise<void> {
        await __request({
            method: 'DELETE',
            path: `/voice_broadcasting/${meetingId}/delete-voicemail-audio`,
        });
    }

}