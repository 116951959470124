import React from 'react';
import { AppBarProps } from '@mui/material/AppBar';
import Link from '@mui/material/Link';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

import { useAuthFinished } from 'hooks/auth/useAuthFinished';
import AvatarPopover from './AvatarPopover';
import { WAppBar, WLogo, RightSide, AlertGrid, AlertBox, AlertTooltip } from './App.styles';
import RoleSwitcher from './RoleSwitcher';
import LanguageSwitcher from './LanguageSwitcher';

export interface AppHeaderProps extends AppBarProps {
	hideUser?: boolean;
	noHomeLink?: boolean;
	showAttendeeRoleSwitcher?: boolean;
	onMenuClick?: () => void;
}

const AppHeader = ({
	hideUser,
	noHomeLink,
	showAttendeeRoleSwitcher,
	onMenuClick,
	...props
}: AppHeaderProps) => {
	const [, setAuthFinished] = useAuthFinished();

	return (
		<WAppBar color={'primary'} {...props}>
			<Grid
				container
				sx={{
					display: 'flex',
					alignItems: 'center',
					flex: 1,
					position: 'relative'
				}}
			>
				<Grid item sx={{ display: 'flex', alignItems: 'center' }}>
					{onMenuClick && (
						<Box sx={{ display: { md: 'block', lg: 'none' } }}>
							<IconButton color={'inherit'} onClick={onMenuClick}>
								<MenuIcon />
							</IconButton>
						</Box>
					)}
					{noHomeLink ? (
						<WLogo />
					) : (
						<Link href={'/'} onClick={() => setAuthFinished(false)}>
							<WLogo />
						</Link>
					)}
				</Grid>

				{process.env.REACT_APP_ALERT && (
					<AlertGrid item>
						<AlertBox>
							<WarningAmberIcon sx={{
								fontSize: '1.7rem',
								strokeWidth: 0.2,
								stroke: '#fff'
							}} />
							System Notice
							<AlertTooltip className="tooltip">
								{process.env.REACT_APP_ALERT}
							</AlertTooltip>
						</AlertBox>
					</AlertGrid>
				)}

				<Grid item sx={{ marginLeft: 'auto' }}>
					<RightSide>
						<LanguageSwitcher />
						{!hideUser && <AvatarPopover />}
						{showAttendeeRoleSwitcher && <RoleSwitcher />}
					</RightSide>
				</Grid>
			</Grid>
		</WAppBar>
	);
};

export default AppHeader;
