/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CalloutInfoResponse } from '../models/CalloutInfoResponse';
import type { MeetingCalloutSettingsResponse } from '../models/MeetingCalloutSettingsResponse';
import type { SetCalloutSettingsRequest } from '../models/SetCalloutSettingsRequest';
import { request as __request } from '../core/request';

export class CallService {

    /**
     * @returns CalloutInfoResponse
     * @throws ApiError
     */
    public static async getCalloutInfo(): Promise<CalloutInfoResponse> {
        const result = await __request({
            method: 'GET',
            path: `/calls/callout/info`,
        });
        return result.body;
    }

    /**
     * @returns CalloutInfoResponse
     * @throws ApiError
     */
    public static async startCallout(): Promise<CalloutInfoResponse> {
      const result = await __request({
        method: 'POST',
        path: '/calls/callout/start/'
      });
      return result.body;
    }

        /**
     * @returns CalloutInfoResponse
     * @throws ApiError
     */
      public static async startCalloutExternal(): Promise<CalloutInfoResponse> {
        const result = await __request({
          method: 'POST',
          path: '/calls/callout/start/external'
        });
        return result.body;
      }

    /**
     * @param meetingId
     * @returns MeetingCalloutSettingsResponse
     * @throws ApiError
     */
    public static async getCalloutSettings(
        meetingId: number,
    ): Promise<MeetingCalloutSettingsResponse> {
        const result = await __request({
            method: 'GET',
            path: `/calls/${meetingId}/callout/settings`,
        });
        return result.body;
    }

    /**
     * @param meetingId
     * @param requestBody
     * @returns MeetingCalloutSettingsResponse
     * @throws ApiError
     */
    public static async setCalloutSettings(
        meetingId: number,
        requestBody: SetCalloutSettingsRequest,
    ): Promise<MeetingCalloutSettingsResponse> {
        const result = await __request({
            method: 'POST',
            path: `/calls/${meetingId}/callout/settings`,
            body: requestBody,
        });
        return result.body;
    }

        /**
     * @param meetingId
     * @returns MeetingCalloutSettingsResponse
     * @throws ApiError
     */
        public static async startLookupPhone(
          meetingId: number,
      ): Promise<MeetingCalloutSettingsResponse> {
          const result = await __request({
              method: 'POST',
              path: `/calls/${meetingId}/phone-lookup`,
          });
          return result.body;
      }

        /**
     * @param meetingId
     * @returns MeetingCalloutSettingsResponse
     * @throws ApiError
     */
        public static async startLookupPhoneWithTwilio(
          meetingId: number,
      ): Promise<MeetingCalloutSettingsResponse> {
          const result = await __request({
              method: 'POST',
              path: `/calls/${meetingId}/twilio-phone-lookup`,
          });
          return result.body;
      }

      /**
       * Uploads a callout audio file for a specific meeting.
       * 
       * @param meetingId The ID of the meeting.
       * @param file The audio file to upload.
       * @throws ApiError if the file upload fails.
       */
      public static async uploadCalloutAudio(meetingId: number, file: File) {
        try {
          const response = await __request({
            method: 'POST',
            path: `/calls/${meetingId}/upload-callout-audio`,
            formData: { file },
          });
          if (!response.ok) throw new Error('Failed to upload file');
        } catch (error) {
          console.error('Error uploading file:', error);
          throw error;
        }
      }

      /**
       * Uploads a voicemail audio file for a specific meeting.
       * 
       * @param meetingId The ID of the meeting.
       * @param file The audio file to upload.
       * @throws ApiError if the file upload fails.
       */
      public static async uploadVoicemailAudio(meetingId: number, file: File) {
        try {
          const response = await __request({
            method: 'POST',
            path: `/calls/${meetingId}/upload-voicemail-audio`,
            formData: { file },
          });
          if (!response.ok) throw new Error('Failed to upload file');
        } catch (error) {
          console.error('Error uploading file:', error);
          throw error;
        }
      }

      /**
       * Retrieves the download URL for the callout audio file for a specific meeting.
       * 
       * @param meetingId The ID of the meeting.
       * @returns The download URL and filename.
       * @throws ApiError if the request fails.
       */
      public static async getCalloutAudioDownloadUrl(meetingId: number): Promise<{ url: string; filename: string; fileSize: number }> {
        const result = await __request({
          method: 'GET',
          path: `/calls/${meetingId}/download-callout-audio`,
        });
        return result.body;
      }
      
      /**
       * Retrieves the download URL for the voicemail audio file for a specific meeting.
       * 
       * @param meetingId The ID of the meeting.
       * @returns The download URL and filename.
       * @throws ApiError if the request fails.
       */
      public static async getVoicemailAudioDownloadUrl(meetingId: number): Promise<{ url: string; filename: string; fileSize: number }> {
        const result = await __request({
          method: 'GET',
          path: `/calls/${meetingId}/download-voicemail-audio`,
        });
        return result.body;
      }
      
      /**
       * Deletes the callout audio file for a specific meeting.
       * 
       * @param meetingId The ID of the meeting.
       * @returns The response from the server.
       * @throws ApiError if the request fails.
       */
      public static async deleteCalloutAudio(meetingId: number): Promise<any> {
        const result = await __request({
          method: 'DELETE',
          path: `/calls/${meetingId}/delete-callout-audio`,
        });
        return result.body;
      }
      
      /**
       * Deletes the voicemail audio file for a specific meeting.
       * 
       * @param meetingId The ID of the meeting.
       * @returns The response from the server.
       * @throws ApiError if the request fails.
       */
      public static async deleteVoicemailAudio(meetingId: number): Promise<any> {
        const result = await __request({
          method: 'DELETE',
          path: `/calls/${meetingId}/delete-voicemail-audio`,
        });
        return result.body;
      }
}